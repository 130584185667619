.privacy-container {
    font-family: Arial, sans-serif;
    margin: 20px;
}

header {
    background-color: #f8f8f8;
    padding: 10px 0;
    text-align: center;
}

main {
    padding: 20px;
}

section {
    margin-bottom: 20px;
}

h1, h2 {
    color: #333;
}

p, ul {
    color: #666;
}

footer {
    text-align: center;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
}
