.profile-container {
  max-width: 1200px;
  margin: 1rem auto 2rem;
  padding: 0 1rem;
}

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* padding: 2rem;
  gap: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem; */
}

.profile-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-info {
  flex: 1;
}

.profile-info h2 {
  margin: 0 0 0.5rem;
  color: #003860;
}

.profile-info p {
  margin: 0.5rem 0;
  color: #666;
}

.profile-info svg {
  margin-right: 0.5rem;
}

.profile-stats {
  display: flex;
  gap: 2rem;
  margin-bottom: 10px;
  justify-content: center;
}

.stat-item {
  text-align: center;
}

.stat-value {
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  color: #003860;
}

.stat-label {
  font-size: 0.875rem;
  color: #666;
}

.profile-content {
  background: white;
  border-radius: 10px;
  padding: 1.5rem;
  padding-top: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-tabs {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  padding-top: 1rem;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 1rem;
  flex-wrap: wrap;
  position: sticky !important;
  top: 72px !important;
  background-color: white;
  z-index: 1000;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
}

.tab-button {
  background: none;
  border: none;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  color: #666;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tab-button:hover {
  background-color: #f8f9fa;
  color: #003860;
}

.tab-button.active {
  background-color: #00518F;
  color: #ddd;
  font-weight: 600;
}

.tab-button svg {
  font-size: 1.1rem;
}

.history-section h3 {
  margin-bottom: 1rem;
  color: #003860;
}

.history-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.history-item {
  text-decoration: none;
  color: inherit;
  padding-left: 1rem;
  border-radius: 8px;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
}

.history-item:hover {
  background-color: #e9ecef;
  transform: translateY(-2px);
}

.history-item-content h4 {
  margin: 0 0 0.5rem;
  color: #003860;
}

.history-item-meta {
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
  color: #666;
}

.item-type, .item-date {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.bookmark-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
}

.bookmark-item:hover {
  background-color: #e9ecef;
  transform: translateY(-2px);
}

.bookmark-link {
  flex: 1;
  text-decoration: none;
  color: inherit;
}

.bookmark-content {
  width: 100%;
}

.bookmark-content h4 {
  margin: 0 0 0.5rem;
  color: #003860;
}

.bookmark-meta {
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
  color: #666;
}

.remove-bookmark-btn {
  background: none;
  border: none;
  color: #dc3545;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.7;
  border-radius: 4px;
}

.remove-bookmark-btn:hover {
  opacity: 1;
  background-color: rgba(220, 53, 69, 0.1);
}

.remove-bookmark-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.bookmarks-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.bookmark-category h3 {
  margin-bottom: 1rem;
  color: #003860;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.bookmark-category h3 svg {
  color: #6c757d;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.tab-button {
  flex: 1;
  min-width: 120px;
  justify-content: center;
}
