/* Navigation.css */
.menu-toggle {
    display: flex;
    align-items: center;
  }
  
  .mobile-nav {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1100;
  }
  
  .mobile-nav.open {
    display: block;
    animation: fadeIn 0.3s ease-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .mobile-nav a {
    text-decoration: none; /* Remove underline */
  }

  /* .logo-text {
    text-shadow: 1px -1px 0 #767676, -1px 2px 1px #737272, -2px 4px 1px #767474, -3px 6px 1px #787777, -4px 8px 1px #7b7a7a, -5px 10px 1px #7f7d7d, -6px 12px 1px #828181, -7px 14px 1px #868585, -8px 16px 1px #8b8a89, -9px 18px 1px #8f8e8d, -10px 20px 1px #949392, -11px 22px 1px #999897, -12px 24px 1px #9e9c9c, -13px 26px 1px #a3a1a1, -14px 28px 1px #a8a6a6, -15px 30px 1px #adabab, -16px 32px 1px #b2b1b0, -17px 34px 1px #b7b6b5, -18px 36px 1px #bcbbba, -19px 38px 1px #c1bfbf, -20px 40px 1px #c6c4c4, -21px 42px 1px #cbc9c8, -22px 44px 1px #cfcdcd, -23px 46px 1px #d4d2d1, -24px 48px 1px #d8d6d5, -25px 50px 1px #dbdad9, -26px 52px 1px #dfdddc, -27px 54px 1px #e2e0df, -28px 56px 1px #e4e3e2, 0px 1px 10px #CE5937;
  } */

  .login-button {
    border-radius: '15px';
    /* box-shadow: '0 1px 2px rgba(0, 0, 0, 0.7)', */
  }

.navbar-custom {
  background-color: #003860;
  padding: 0.5rem 1rem;
  position: sticky;
  top: 0;
  z-index: 1100;
}

.navbar-custom .navbar-collapse {
  z-index: 1100;
}

.navbar-custom .navbar-brand {
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
}

.navbar-custom .nav-link {
  color: rgba(255, 255, 255, 0.8) !important;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
}

.navbar-custom .nav-link:hover {
  color: white !important;
}

.navbar-custom .nav-link.active {
  color: white !important;
  font-weight: 600;
}

.navbar-custom .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.9);
}

.navbar-custom .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.8%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.btn-outline-light {
  transition: all 0.3s ease;
}

.btn-outline-light:hover {
  background-color: white;
  color: #003860;
}

/* User info styles */
.user-info {
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}