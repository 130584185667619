/* topicDetailsStyle.css */

/* Container for the sections (beginner, intermediate, advanced) */
.sectionsContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
}

/* For larger screens (three-column layout) */
.container {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    font-family: Arial, sans-serif;
    /* font-size: 30px; */
}

.tutorial-container {
    display: flex;
    gap: 20px;
    max-width: 1200px;
    margin: auto;
}

.content-column {
    flex: 2; /* Takes two-thirds of the width */
}

.ad-column {
    flex: 1; /* Takes one-third of the width */
    /* background: #f9f9f9; */
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 768px) {
    .tutorial-container {
        flex-direction: column;
    }

    .ad-column {
        margin-top: 20px;
    }
}
