/* topicDetailsStyle.css */

/* Container for the sections (beginner, intermediate, advanced) */
.topicCard {
    height: 100%;
    background-color: #dee8eb;
    padding: 0px;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.0);
}

.card-hover {
    transition: background-color 0.3s ease;
}

.card-hover:hover {
    background-color: #f4f7fa; 
    color: white;
    cursor: pointer;
    transform: scale(1.05);
}

/* .cardText {
    font-size: 0.9rem;
} */

/* .cardText {
    font-size: clamp(0.7rem, 2vw, 1rem);
} */

.cardText {
    font-size: 0.9rem; /* Default size */
}

/* .topicsContainer {
    margin-top: 40px;
} */

.topicsForm {
    border: 1px solid #bbb;
    border-radius: 0.375rem;
    /* box-shadow: none; */
    padding: 5px;
    font-size: 1rem;
    background-color: #ffffff;
}

.navigationPrevious {
    margin-right: 10px;
    padding: 5px 15px; 
    font-size: 1rem; 
    background-color: #00518F; 
    border: none; 
    width: 120px;
}

.navigationNext {
    margin-left: 10px;
    padding: 5px 15px; 
    font-size: 1rem; 
    background-color: #00518F; 
    border: none; 
    width: 120px;
}

/* .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } */

  .spinner-container {
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center;    /* Vertical centering */
    height: 100%;           /* Set the height to fill the parent container */
  }

  .tabs-bottom {
    display: none;
}

.links-top {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}