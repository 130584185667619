/* Container Styles */
.login-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 5rem;
}

/* Title Styles */
.login-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

/* Social Auth Container Styles */
.social-auth-container {
  margin-top: 1rem;
}

/* Error Message Styles */
.error-message {
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  color: #dc2626;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
}

/* Social Buttons Container Styles */
.social-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

/* Common Social Button Styles */
.social-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1.5rem;
  font-weight: 300;
  cursor: pointer;
  transition: opacity 0.2s;
  color: white;
  margin-top: 20px;
  align-items: center;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
}

.social-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.social-btn svg {
  margin-right: 0.75rem;
  font-size: 1.25rem;
}

/* Google Button Styles */
.google {
  background-color: #f73a3a;
}

/* Facebook Button Styles */
.facebook {
  background-color: #1877f2;
}

/* GitHub Button Styles */
.github {
  background-color: #24292e;
}

/* Hover Styles */
.google:hover:not(:disabled),
.facebook:hover:not(:disabled),
.github:hover:not(:disabled) {
  opacity: 0.9;
}

/* Divider styles */
.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #e5e7eb;
}

.divider span {
  padding: 0 1rem;
  color: #6b7280;
  font-size: 0.875rem;
}