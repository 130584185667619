.sticky-tabs {
    display: flex;
    position: sticky !important;
    top: 77px !important;
    background-color: white;
    z-index: 1000;
    padding: 0.5rem;
    /* padding: 10px 0;
    margin: 0;
    border-bottom: 1px solid #dee2e6; */
    /* align-items: center;
    justify-content: center; */
}

.tab-panels-container {
    margin-top: 20px;
    gap: 10px;
}

/* Override react-tabs styles for our custom implementation */
.react-tabs__tab-list {
    /* border-bottom: 1px solid #aaa; */
    margin: 0 0 10px;
    padding: 0;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    /* border-bottom: none; */
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    background: #f8f9fa;
}

.react-tabs__tab--selected {
    background: #0e7ba7;
    border-color: #aaa;
    color: white;
    border-radius: 5px;
    /* border-radius: 5px 5px 0 0; */
}
