.topic-card-container {
    perspective: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.topic-card {
    position: relative;
    width: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.topic-card.flipped {
    transform: rotateY(180deg);
}

.card-face {
    width: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.card-front {
    transform: rotateY(0deg);
}

.card-back {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateY(180deg);
}

.card {
    width: 100%;
    height: 100%; /* Ensure cards take up the full height of their container */
    display: flex;
    flex-direction: column;
    min-height: 200px;
    margin-bottom: 0px;;
}

.card-body {
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure the card body stretches */
}

.card-text {
    flex-grow: 1; /* This allows the text to take up remaining space and stretch the card */
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.card-text.expanded {
    max-height: none !important;
}

.topicCard {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* margin-bottom: 1rem; */
}

.topicCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.topicsForm1 {
    border: none !important;
    box-shadow: none !important;
    background-color: #f8f9fa !important;
}
