.image-links-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Responsive columns */
    gap: 20px;             /* Space between items */
    padding: 20px;         /* Optional: padding around the container */
    justify-items: center; /* Center items horizontally within the grid */
  }
  
  .image-link-item {
    text-align: center;    /* Center text under the image */
  }
  
  .image-link {
    width: 90%;           /* Image takes full width of its container */
    max-width: 200px;
    height: auto;          /* Maintain aspect ratio */
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    transition: transform 0.2s ease-in-out;
  }
  
  .image-link:hover {
    transform: scale(1.05);
  }
  
  .description {
    margin-top: 10px;      /* Space between the image and the description */
    font-size: 14px;       /* Set the font size for the description */
    color: #555;           /* Set a color for the description text */
  }
  