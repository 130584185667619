.interview-questions {
    /* padding: 5px; */
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.tab-list {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;
}

.tab-list .react-tabs__tab {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    background: #e0e0e0;
    font-weight: bold;
}

.tab-list .react-tabs__tab--selected {
    background: #6200ea;
    color: #fff;
}

.questions-container {
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.question-container {
    padding-left: 15px;
    padding-right: 15px;
    /* margin-bottom: 10px; */
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.question-container.active {
    background: #f3f3f3;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.code-example {
    background-color: #282c34;
    color: #61dafb;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    overflow-x: auto;
}

.more-button {
    background-color: #6200ea;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 5px;
    transition: background-color 0.2s;
}

.more-button:hover {
    background-color: #3700b3;
}

.interview-questions-container {
    display: flex;
    gap: 20px;
    max-width: 1200px;
    margin: auto;
}

.content-column {
    flex: 2; /* Takes two-thirds of the width */
}

.ad-column {
    flex: 1; /* Takes one-third of the width */
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 768px) {
    .interview-questions-container {
        flex-direction: column;
    }

    .ad-column {
        margin-top: 20px;
    }
}
