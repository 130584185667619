.bookmark-button {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #6c757d;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1100;
}

.bookmark-button:hover {
  background-color: rgba(108, 117, 125, 0.1);
  transform: scale(1.1);
}

.bookmark-button.active {
  color: #FF6800;
}

.bookmark-button.active:hover {
  color: #FF6800;
}

.bookmark-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.bookmark-button:disabled:hover {
  transform: none;
  background: none;
}

.bookmark-button .fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Tooltip */
.bookmark-button[title]:hover::after {
  content: attr(title);
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1000;
}

/* Add arrow to tooltip */
.bookmark-button[title]:hover::before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
  z-index: 1000;
}
