.home-container {
    font-family: Arial, sans-serif;
    margin: 20px;
    /* background-color: #f8f9fa; */
}

header {
    /* background-color: #f8f8f8; */
    padding: 20px;
    text-align: center;
}

header h1 {
    color: #333;
    margin-bottom: 10px;
}

header p {
    color: #666;
    margin-bottom: 20px;
}

.value-proposition {
    /* background-color: #e8f4f8; */
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.value-proposition h2 {
    color: #333;
}

.value-proposition p {
    color: #666;
    margin: 20px 0;
}

.value-proposition button {
    padding: 10px 20px;
    font-size: 16px;
    /* background-color: #007bff; */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.value-proposition button:hover {
    /* background-color: #0056b3; */
}

.stats {
    /* background-color: #fff; */
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.stats h2 {
    color: #333;
    margin-bottom: 20px;
}

.card {
    margin-bottom: 20px;
}

.coming-soon {
    /* background-color: #f0f0f0; */
    padding: 0px;
    text-align: center;
    margin-bottom: 20px;
}

.coming-soon h2 {
    color: #333;
    margin-bottom: 20px;
}

footer {
    text-align: center;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
    color: #666;
}

.hero {
    text-align: center;
    padding: 30px 20px;
    /* background-color: #003860; */
    color: white;
    margin-bottom: 10px;
    border-radius: 10px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); Optional shadow for visual depth */
}

.hero h1 {
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.hero p {
    /* font-size: 1.3rem; */
    margin-bottom: 30px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
}

.features-section {
    padding: 10px 0;
}

.feature-card {
    background: white;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
    transition: transform 0.3s ease;
    border: 1px solid #ccc;
}

.feature-card:hover {
    transform: translateY(-5px);
}

.feature-icon {
    color: #003860;
    margin-bottom: 20px;
}

.feature-image {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tech-graph-section {
    padding: 20px 0;
    /* background-color: white; */
    text-align: center;
    margin: 10px 0;
}

.tech-graph-section h2 {
    color: #003860;
    margin-bottom: 30px;
}

.benefits-section {
    padding: 40px 0;
}

.benefits-list-card {
    background: white;
    border-radius: 10px;
    padding: 20px;
    height: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.benefits-list-card ul {
    list-style: none;
    padding: 0;
}

.benefits-list-card li {
    margin: 15px 0;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}

.benefits-list-card li svg {
    color: #28a745;
    margin-right: 10px;
}

.cta-section {
    padding: 10px 0;
    background-color: #f8f9fa;
}

.cta-section .card {
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.cta-section h2 {
    color: #003860;
    margin-bottom: 20px;
}

.cta-section p {
    /* font-size: 1.2rem; */
    margin-bottom: 30px;
}

.btn-primary {
    background-color: #003860;
    border-color: #003860;
    padding: 10px 25px;
    font-size: 1.1rem;
    transition: all 0.3s ease;
}

.btn-primary:hover {
    background-color: #002540;
    border-color: #002540;
    transform: translateY(-2px);
}

/* Text alignment utility */
.text-center {
    text-align: center !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .hero h1 {
        font-size: 1rem;
    }
    
    .hero p {
        font-size: 1.1rem;
    }
    
    .feature-card {
        margin-bottom: 20px;
    }
}
