/* App.css */

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-size: clamp(16px, 1.5vw, 20px);
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  width: 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 10px;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 1100;
  background-color: #003860;
  width: 100%;
}

.navbarx .home-link img {
  height: 40px;
}

.navbarx .links {
  display: flex;
  gap: 1rem;
}

.navbarx .links a {
  color: white;
  text-decoration: none;
}

.navbarx .login-buttonx {
  background-color: #00E0BB;
  border: none;
  padding: 0.5rem 1rem;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.container {
  margin-top: 0px;
}

.App-logo {
  display: flex;
  height: 60px;
  pointer-events: none;
}

.beginner {
  background-color: #b2cbf6;
  color: black;
  font-size: larger;
}

.intermediate {
  background-color: #6f86ad;
  color: white;
  font-size: larger;
}

.advanced {
  background-color: #46546c;
  color: white;
  font-size: larger;
}